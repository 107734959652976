
<template>
  <PageContainer>
    <BaseSearchForm
      @search="onSearch"
      @reset="onReset"
      :loading="loading">
      <el-form-item label="所属区域:">
        <el-input v-model.trim="searchForm.areaName" clearable placeholder="请输入" />
      </el-form-item>
      <el-form-item label="企业名称:">
        <el-select v-model="searchForm.compName" filterable placeholder="全部企业" clearable>
          <el-option v-for="item in companyList" :key="item" :value="item" :label="item"/>
        </el-select>
      </el-form-item>
      <el-form-item label="报警日期:">
        <el-date-picker
          v-model="sendDate"
          clearable
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="报警类型:">
        <el-select v-model="searchForm.warnType" filterable placeholder="报警类型" clearable>
          <el-option :value="1" label="未提交月报表"/>
          <el-option :value="2" label="未提交年报表"/>
        </el-select>
      </el-form-item>
    </BaseSearchForm>

    <BaseTable
      id="warningRecord"
      @page-change="handlePageChange"
      :tablePage="tablePage"
      :loading="loading"
      :data="tableData">
      <vxe-column type="seq" title="序号" align="center" width="80"></vxe-column>
      <vxe-column field="sendTime" title="报警日期"></vxe-column>
      <vxe-column field="areaName" title="区域名称">
        <template v-slot="{row}">
          <div class="flex align-center justify-center w-100-p" style="flex-wrap: nowrap;" v-if="row.areaName">
            <el-tag class="text-1line" style="flex-shrink:1;">{{row.areaName.split(',').filter(item => Boolean(item))[0]}}</el-tag>
            <el-popover
              placement="top-start"
              trigger="click">
              <div class="flex justify-start" style="max-width:800px;">
                <el-tag style="margin: 3px;" v-for="item in row.areaName.split(',').filter(item => Boolean(item))" :key="item">{{item}}</el-tag>
              </div>
              <div slot="reference" class="pointer ml-5" title="点击看详情">
                共{{row.areaName.split(',').filter(item => Boolean(item)).length}}条
              </div>
            </el-popover>
          </div>
        </template>
      </vxe-column>
      <vxe-column field="compName" title="企业名称">
        <template v-slot="{row}">
          <div class="flex align-center justify-center w-100-p" style="flex-wrap: nowrap;" v-if="row.compName">
            <el-tag class="text-1line" style="flex-shrink:1;">{{row.compName.split(',').filter(item => Boolean(item))[0]}}</el-tag>
            <el-popover
              placement="top-start"
              trigger="click">
              <div class="flex justify-start"  style="max-width:800px;">
                <el-tag style="margin: 3px;" v-for="item in row.compName.split(',').filter(item => Boolean(item))" :key="item">{{item}}</el-tag>
              </div>
              <div slot="reference" class="pointer ml-5" title="点击看详情">
                共{{row.compName.split(',').filter(item => Boolean(item)).length}}家
              </div>
            </el-popover>
          </div>
        </template>
      </vxe-column>
      <vxe-column field="messageReceiver" title="接收人姓名"></vxe-column>
      <vxe-column field="messagePhone" title="联系方式" ></vxe-column>
      <vxe-column field="warnTypeName" title="报警类型" ></vxe-column>
      <vxe-column field="warnReason" title="报警原因" ></vxe-column>
      <vxe-column field="content" title="短信内容" ></vxe-column>
      <vxe-column field="typeName" title="短信发送方式" ></vxe-column>
    </BaseTable>
  </PageContainer>
</template>

<script>
import { getMessageSendLogList } from '@/apis/warningManage/msgManage'
import { gianComplist } from '@/apis/common'
export default {
  data () {
    return {
      companyList: [],
      searchForm: {
        areaName: '', // 区域名称
        compName: '', // 企业名称
        warnType: '', // 报警类型
        startTime: '', // 开始时间
        endTime: '' // 结束时间
      },
      // 表格相关
      tableData: [],
      tablePage: {
        pageNum: 1,
        pageSize: 20,
        totalSize: 0
      },
      loading: false
    }
  },
  computed: {
    sendDate: {
      get () {
        const searchForm = this.searchForm
        if (searchForm.startTime && searchForm.endTime) {
          const [startDate] = searchForm.startTime.split(' ')
          const [endDate] = searchForm.endTime.split(' ')
          return [startDate, endDate]
        } else {
          return null
        }
      },
      set (newVal) {
        const searchForm = this.searchForm
        if (newVal) {
          const [startDate, endDate] = newVal
          searchForm.startTime = startDate + ' 00:00:00'
          searchForm.endTime = endDate + ' 23:59:59'
        } else {
          searchForm.startTime = ''
          searchForm.endTime = ''
        }
      }
    }
  },
  created () {
    this.initList()
    this.initCompany()
  },
  methods: {
    async initCompany () {
      const res = await gianComplist({ pageNum: 1, pageSize: 100000 })
      if (res.code == 200) {
        const companyList = res.data.rows.map(item => item.compName)
        this.companyList = [...new Set(companyList)]
        console.log(this.companyList)
      } else {
        this.$message.error(res.msg)
      }
    },
    onReset () {
      const source = this.$options.data(this).searchForm
      this.searchForm = Object.assign({}, this.searchForm, source)
      this.onSearch()
    },
    onSearch () {
      this.tablePage.pageNum = 1
      this.initList()
    },
    initList () {
      this.loading = true
      const { totalSize, ...pageParams } = this.tablePage
      const params = Object.assign({}, this.searchForm, pageParams)
      getMessageSendLogList(params).then((res) => {
        this.loading = false
        if (res.code == 200) {
          this.tablePage.totalSize = res.data.totalSize
          this.tableData = res.data.rows.map((item) => {
            // 短信类型 1 手动 2 自动
            item.typeName = item.type == 1 ? '手动' : '自动'
            // 报警类型 1 未提交月报表 2 未提交年报表
            item.warnTypeName = item.warnType == 1 ? '未提交月报表' : '未提交年报表'
            return item
          })
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.pageNum = currentPage
      this.tablePage.pageSize = pageSize
      this.initList()
    }
  }
}
</script>
